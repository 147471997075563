import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { Prompt, withRouter } from "react-router-dom";
import { EditingState } from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableEditRow,
    TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import Password from "./Password"
import RedSnackbar from "./RedSnackbar";
import axios from 'axios';
import ls from 'local-storage';
import * as qs from 'query-string';

const mockSteris = [{"id": "704/SteriA", "name": "Midmark", "doctor_name": "Doctor A", "sin": "001"}, {"id": "704/SteriB", "name": "Statim", "doctor_name": "Doctor B", "sin": "002"}];

const styles = theme => ({
    root: {
        width: '100%'
    },
    settings: {
        overflowX: 'auto',
        padding: '20px'
    },
    textField: {
        verticalAlign: 'middle',
        marginLeft: theme.spacing.unit * 1,
        marginRight: theme.spacing.unit * 1,
        marginTop: '0px',
        width: '300px',
        display: 'flex'
    },
    leftIcon: {
        marginRight: theme.spacing.unit * 1
    },
    button: {
        display: 'flex',
        margin: '10px'
    }
});

const validate = (email) => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(email).toLowerCase())
}

const getRowId = row => row.id;

const columns = [
    { name: 'id', title: 'Steri-Scan ID'},
    { name: 'name', title: 'Name' },
    { name: 'doctor_name', title: 'Doctor Name' },
    { name: 'sin', title: 'Serial Number' }
];

class Settings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            firstTime: qs.parse(window.location.search).firsttime !== undefined,
            email: "",
            password: "",
            newPassword: "",
            open: false,
            message: "",
            data: [],
            mock: this.props.mock,
        };

        this.handler = this.handler.bind(this)

    }

    componentDidMount() {
        if (this.state.firstTime) {
            this.setState({
                password: "99999"
            });
        }
        if (this.state.mock) {
            this.setState({
                email: ls.get("email"),
                data: mockSteris
            });
        } else {
            let lookup = {};
            for (const [k, v] of Object.entries(this.props.steriLoggers)) {
                lookup[v] = k.split("/")[0];
            }
            // Get steri-scan names.
            for (let steriLoggerUri of new Set(Object.values(this.props.steriLoggers))) {
                axios.get(steriLoggerUri + "/getinfo"
                    ).then(response => {
                        if (response.status === 200) {
                            const indexLookup = {0: "SteriA", 1: "SteriB", 2: "SteriC", 3: "SteriD"};
                            let responseData = response.data["SteriScan Info"];
                            let result = [];
                            
                            for (const [i, [name, doctor_name, sin]] of responseData.entries()) {
                                result.push({"id": lookup[steriLoggerUri] + "/" + indexLookup[i], "name": name, "doctor_name": doctor_name, "sin": sin});
                            }
                            
                            this.setState(state => {
                                const data = state.data.concat(result);
                                return {
                                  data
                                };
                            });
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }
    

            axios.get(Object.values(this.props.steriLoggers)[0] + "/email"
            ).then(response => {
                if (response.status === 200) {
                    this.setState({
                        email: response.data
                        });
                }
            }).catch(error => {
                console.log(error);
                // this.setState({
                //     responseStatus: error.response.status
                // });
            });
        }
    }

    commitChanges = ({ added, changed, deleted }) => {
        let rows = this.state.data;

        if (Object.values(changed)[0]) {    
            if ("name" in Object.values(changed)[0]) {
            if (!Object.values(changed)[0].name || Object.values(changed)[0].name.length > 10) {
                this.setState({
                    message: "Alias must contain less than 10 characters.",
                    open: true,
                });
                return;
            }
            }
            if ("doctor_name" in Object.values(changed)[0]) {
                if (!Object.values(changed)[0].doctor_name || Object.values(changed)[0].doctor_name.length > 18) {
                    this.setState({
                        message: "Doctor's name must contain less than 18 characters.",
                        open: true,
                    });
                    return;
                }
            }
            if ("sin" in Object.values(changed)[0]) {
                if (!Object.values(changed)[0].sin || Object.values(changed)[0].sin.length > 14) {
                    this.setState({
                        message: "Serial number must contain less than 14 characters.",
                        open: true,
                    });
                    return;
                }
            }

            this.setState({
                data: rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row))
            });
        }
    }

    updateSettings = () => {
        if (this.state.firstTime) {
            console.log(this.state.password);
            // do some validation on email and password.
            if (this.state.newPassword.length !== 5) {
            this.setState({ 
                message: "Password must contain exactly five digits.",
                open: true,
                loading: false });
            return;            
            }
            if (!this.state.newPassword.match(/^[0-9]*$/)) {
                this.setState({ 
                    message: "Password must only contain numbers.",
                    open: true,
                    loading: false });
                return;            
            } 
            if (this.state.newPassword === "99999" || this.state.newPassword === "99998") {
                this.setState({ 
                    message: "This password is reserved, please choose another.",
                    open: true,
                    loading: false });
                return;            
            }
        }

        if (!validate(this.state.email.trim())) {
            this.setState({ 
                message: "You must provide a valid email address.",
                open: true,
                loading: false });
            return;                     
        }

        if (this.state.mock) {
            let sterinames = {};
            for (const entry of this.state.data) {
                sterinames[entry["id"]] = entry["name"];
               }
            ls.set("sterinames", sterinames);
            ls.set("email", this.state.email);
            if (this.state.firstTime) {
                ls.set("password", this.state.newPassword);
                this.setState({
                    firstTime: false
                });
                this.props.history.push('/');
            }
        } else {
            // Always use first steriscan to save email.
            axios.get(Object.values(this.props.steriLoggers)[0] + "/addemail/" + this.state.password + "/" + this.state.email.trim()
            ).then(response => {
                if (response.status === 200) {
                    // do a snackbar thing.
                }
            }).catch(error => {
                console.log(error);
                // this.setState({
                //     responseStatus: error.response.status
                // });
            });

            for (let i=0 ; i <this.state.data.length; i+=2) {
                let steriId = this.state.data[i]["id"];                
                let steriUrl = this.props.steriLoggers[steriId];

                let payload = Object.values(this.state.data[i]).concat(Object.values(this.state.data[i+1]));
                payload.splice(0, 1);
                payload.splice(3, 1);

                // use steriId (like 705/SteriA) to get nameA, nameB.
                axios.get(steriUrl + "/setinfo/" + this.state.password + "/" + payload.join(",")
                ).then(response => {
                    if (response.status === 200) {
                        ls.remove("sterinames");
                        // do a snackbar thing.
                    }
                }).catch(error => {
                    console.log(error);
                });
            }

            if (this.state.firstTime) {
                axios.get(Object.values(this.props.steriLoggers)[0] + "/updatepw/" + this.state.password + "/" + this.state.newPassword
                ).then(response => {
                    if (response.status === 200) {
                        this.setState({
                            firstTime: false
                        });
                        this.props.history.push('/');
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    handler = password => {
        this.setState({
            password
        })
    };

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Prompt
                    when={this.state.firstTime}
                />
                {!this.state.password ? (
                    <Password {...this.props} steriLoggers={this.props.steriLoggers} handler={this.handler} mock={this.state.mock} />
                    ) : (
                        <Paper className={classes.settings}>
                            {this.state.firstTime &&
                            <div>
                                Please set an e-mail and password for your new Steri-Scan system.
                            <p />
                            </div>
                            }
                <TextField
                    label="E-mail"
                    name="email"
                    className={classes.textField}
                    value={this.state.email}
                    onChange={this.handleChange}
                    margin="normal"
                />
                {this.state.firstTime ?
                <TextField
                    label="Password"
                    type="password"
                    name="newPassword"
                    className={classes.textField}
                    value={this.state.newPassword}
                    onChange={this.handleChange}
                    margin="normal"
                />
                :

                <Grid
                        rows={this.state.data}
                        columns={columns}
                        getRowId={getRowId}
                    >
                        <EditingState
                            onCommitChanges={this.commitChanges}
                            columnExtensions={[{ columnName: 'id', editingEnabled: false }]}
                        />
                        <Table />
                        <TableHeaderRow />
                        <TableEditRow />
                        <TableEditColumn
                            showEditCommand
                        />
                    </Grid>
                }

                <Button variant="contained" className={classes.button} onClick={this.updateSettings}>
                    <SaveIcon className={classes.leftIcon} />
                    Save
                </Button>
                <RedSnackbar
                open={this.state.open}
                message={this.state.message}
                onClose={this.handleClose}
            />
                        </Paper>
                    )}
            </div>
        );
    }
}

Settings.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Settings));