import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import CycleListing from './CycleListing';
import BarcodeSearch from './BarcodeSearch';
import SteriloggerList from './SteriloggerList';
import Settings from './Settings';
import Test from './Test';
import ForgotPassword from './ForgotPassword';
import logo from './logo_small.png';
import { withRouter, Route, Link } from "react-router-dom";
import axios from 'axios';
import ls from 'local-storage';
import stripSuffixes from '../utils/utils';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import NotesIcon from '@material-ui/icons/Notes';
import PeopleIcon from '@material-ui/icons/People';
import SearchIcon from '@material-ui/icons/Search';
import PublicIcon from '@material-ui/icons/Public';
import * as qs from 'query-string';

const default_router_ips = ["192.168.15.1", "192.168.123.254", "192.168.8.1", "192.168.0.2", "192.168.0.254", "10.1.1.1", "200.200.200.5", "192.168.0.10", "192.168.0.227", "192.168.55.1", "192.168.1.210", "192.168.100.1", "192.168.1.1", "192.168.10.50", "10.0.0.138", "192.168.0.1", "192.168.1.200", "192.168.1.254", "192.168.10.10", "192.168.0.100", "192.168.10.100", "192.168.2.1", "192.168.0.30", "192.168.0.50", "192.168.0.101", "192.168.0.3", "192.168.223.100", "192.168.16.1", "192.168.30.1", "192.168.4.1", "192.168.20.1", "192.168.3.1", "192.168.102.1", "192.168.100.100", "192.168.1.10", "10.0.1.1", "192.168.1.100", "192.168.168.168", "10.0.0.1", "192.168.251.1", "192.168.50.1", "10.0.0.2", "10.1.10.1", "192.168.10.1", "192.168.11.1", "192.168.254.254", "192.168.1.99", "192.168.86.1", "192.168.62.1", "10.10.1.1", "10.90.90.90", "192.168.1.20", "192.168.10.254", "192.168.118.168", "192.168.118.1", "192.168.15.254"];
export const DEFAULT_PASSWORD = 99999;

let theRouter = [];
let maybeTheRouter = [];
let cidrPrefixes = [];

let steriLoggers = {};

function findRouter(callback) {

    let callbackCounter = 0;

    // mock!!!
    // cidrPrefixes.push("10.0.0.");
    // cidrPrefixes.push("192.168.0.");
    // findSteriloggers(callback);

    for (let value of default_router_ips) {
        axios.get('http://' + value, {
            timeout: 10000
        }).then(response => {
            theRouter.push(response.config.url.replace("http://", ""));
        }).catch(error => {
            if (error.message === "Network Error") {
                maybeTheRouter.push(error.config.url.replace("http://", ""));
            }
        }).then(function () {
            callbackCounter++;
            if (callbackCounter === default_router_ips.length) {
                if (theRouter.length > 0) {
                  for (let routerIp of theRouter) {
                    cidrPrefixes.push(routerIp.substring(0, routerIp.lastIndexOf(".") + 1));
                    cidrPrefixes = [...new Set(cidrPrefixes)];
                  }
                } else if (theRouter.length === 0 && maybeTheRouter.length > 0) {
                    // We didn't find a router, but we *maybe* found the router - let's use it.
                    for (let maybeRouterIp of maybeTheRouter) {
                      cidrPrefixes.push(maybeRouterIp.substring(0, maybeRouterIp.lastIndexOf(".") + 1));
                      cidrPrefixes = [...new Set(cidrPrefixes)];
                    }
                } else {
                    // We couldn't find a router at all.
                    // Last effort webRTC
                    window.RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;   //compatibility for firefox and chrome
                    let pc = new RTCPeerConnection({iceServers:[]}), noop = function(){};
                    pc.createDataChannel("");    //create a bogus data channel
                    pc.createOffer(pc.setLocalDescription.bind(pc), noop);    // create offer and set local description
                    pc.onicecandidate = function(ice){  //listen for candidate events
                        if(!ice || !ice.candidate || !ice.candidate.candidate)  return;
                        var localIp = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/.exec(ice.candidate.candidate)[1];
                        cidrPrefixes.push(localIp.substring(0, localIp.lastIndexOf(".") + 1));
                        pc.onicecandidate = noop;                        
                    };
                }
                findSteriloggers(callback);
            }
        });
    }

}

function findSteriloggers(callback) {
  console.log("router ip(s): " + cidrPrefixes);
  
  let ipSuffixes = [...new Array(255).keys()];

  let dualUnits = [];
  let callbackCounter = 0;
  for (let cidrPrefix of cidrPrefixes) {
    for (let ip of ipSuffixes) {
        axios.get('http://' + cidrPrefix + ip, {
            timeout: 60000
        }).then(response => {
            if (response.headers["content-type"].includes("SteriLogger")) {
              steriLoggers[response.headers["content-type"].split("/")[1]] = response.config.url;
            } else if (response.headers["content-type"].includes("SteriScanDual") || response.headers["content-type"].includes("SteriScanQuad")) {
              // Capture dual unit info, we'll handle the GET in the last then(...) block.
              let dualUnitInfo = {"url": response.config.url, "id": response.headers["content-type"]};
              dualUnits.push(dualUnitInfo);
            }
        }).catch(error => {}).then(function () {
            callbackCounter++;
            if (callbackCounter === ipSuffixes.length*cidrPrefixes.length) {
              // Check whether we found any dual units before calling the callback.
              // If we did, get the id's of the steri-scans for that dual unit.
              if (dualUnits.length > 0) {
                let dualUnitsCallbackCounter = 0;
                for (let dualUnit of dualUnits) {
                  getIdsFromDualUnit(dualUnit.url).then(result => {
                    dualUnitsCallbackCounter++;
                    for (let id of result) {
                      steriLoggers[dualUnit.id.split("/")[1] + "/" + id] = dualUnit.url + "/" + id;
                    }
                    if (dualUnitsCallbackCounter === dualUnits.length) {
                      ls.set("steriLoggers", steriLoggers);
                      callback(steriLoggers);            
                    }
                  });  
                }
              } else {
                ls.set("steriLoggers", steriLoggers);
                callback(steriLoggers);
              }
            }
        });
    }
  }
}

async function getIdsFromDualUnit(url) {
  let steriScanIds = [];
  let response = await axios.get(url, {
    timeout: 60000
          });
  response = response.data.split(",").slice(0, -1);
  for (let id of response) {
    if (id.includes("Steri")) {
      steriScanIds.push(id);
    }
  }
  return steriScanIds;
}

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
});

const mockSteri = {"704/SteriA":"http://192.168.2.81/SteriA","704/SteriB":"http://192.168.2.81/SteriB"};

class Dashboard extends React.Component {

  constructor(props) {  
    super(props);

    this.finish = this.finish.bind(this);
    
    this.state = {
        firstTime: false,
        isLoading: true,
        steriLoggers: ls.get("steriLoggers"),
        open: true,
        mock: qs.parse(this.props.location.search).demo !== undefined,
        manualEntryDialogOpen: ls.get("steriLoggers") && Object.entries(ls.get("steriLoggers")).length === 0,
        steriIps: ''
        };

        if (this.state.mock) {
          this.state.steriLoggers = mockSteri;
        }
    }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });
  
  finish(steriLoggers) {
      this.setState(
          { steriLoggers: steriLoggers,
              isLoading: false,
              manualEntryDialogOpen: Object.entries(steriLoggers).length === 0
          });
      this.syncTime();
      this.isFirstTimeSetup();
  }

  
  handleManualEntry = () => {
    this.setState({
      manualEntryDialogOpen: false
    });
    let steriIps = this.state.steriIps.split(",");
    let steriLoggers = {};
    let dualUnits = [];
    let callbackCounter = 0;
    for (let steriIp of steriIps) {
      axios.get('http://' + steriIp, {
            timeout: 60000
        }).then(response => {
            if (response.headers["content-type"].includes("SteriLogger")) {
              steriLoggers[response.headers["content-type"].split("/")[1]] = response.config.url;
            } else if (response.headers["content-type"].includes("SteriScanDual") || response.headers["content-type"].includes("SteriScanQuad")) {
              // Capture dual unit info, we'll handle the GET in the last then(...) block.
              let dualUnitInfo = {"url": response.config.url, "id": response.headers["content-type"]};
              dualUnits.push(dualUnitInfo);
            }
        }).catch(error => {}).then(() => {
            callbackCounter++;
            if (callbackCounter === steriIps.length) {
              // Check whether we found any dual units before calling the callback.
              // If we did, get the id's of the steri-scans for that dual unit.
              if (dualUnits.length > 0) {
                let dualUnitsCallbackCounter = 0;
                for (let dualUnit of dualUnits) {
                  getIdsFromDualUnit(dualUnit.url).then(result => {
                    dualUnitsCallbackCounter++;
                    for (let id of result) {
                      steriLoggers[dualUnit.id.split("/")[1] + "/" + id] = dualUnit.url + "/" + id;
                    }
                    if (dualUnitsCallbackCounter === dualUnits.length) {
                      ls.set("steriLoggers", steriLoggers);
                      this.finish(steriLoggers);            
                    }
                  });  
                }
              } else {
                ls.set("steriLoggers", steriLoggers);
                this.finish(steriLoggers);
              }
            }
        });
    }
  }

  refreshSteriloggers = () => {
    ls.remove("steriLoggers");
    this.setState({
      isLoading: true
    });
    findRouter(this.finish);
  };

  componentDidMount() {
      if (!Boolean(this.state.steriLoggers)) {
          findRouter(this.finish);
      } else {
          this.syncTime();
          this.isFirstTimeSetup();
      }
  }

  isFirstTimeSetup = () => {  
      // check for first-time setup.
      if (this.state.mock) {
        if (!ls.get("email")) {
          this.props.history.push('/settings?firsttime');
        }

        this.setState({
          isLoading: false
        });
      } else {
        axios.get(Object.values(this.state.steriLoggers)[0] + "/password/" + DEFAULT_PASSWORD
        ).then(response => {
            if (response.status === 200) {
                this.props.history.push('/settings?firsttime');
            }
        }).catch(error => {
            console.log(error);
        });

        if (!ls.get("sterinames")) {
          let steriLoggersStrippedSuffix = new Set(Object.values(stripSuffixes(Object.entries(this.state.steriLoggers))));

          let lookup = {};
            for (const [k, v] of Object.entries(stripSuffixes(Object.entries(this.state.steriLoggers)))) {
                lookup[v] = k.split("/")[0];
            }
            console.log(lookup);

          let result = {};
          let counter = 0;
          if (steriLoggersStrippedSuffix.size === 0) {
            this.setState({
              isLoading: false
            });
          }
          for (const steriUrl of steriLoggersStrippedSuffix) {
            axios.get(steriUrl + "/getinfo"
            ).then(response => {
                if (response.status === 200) {
                  const indexLookup = { 0: "SteriA", 1: "SteriB", 2: "SteriC", 3: "SteriD"};
                  let responseData = response.data["SteriScan Info"];
                  for (const [i, [value]] of responseData.entries()) {
                    result[lookup[steriUrl] + "/" + indexLookup[i]] = value;
                  }
                }
            }).catch(error => {
                console.log(error);
            }).then(() => {
              counter++;
              console.log(counter + " , " + steriLoggersStrippedSuffix.size);
              if (counter === steriLoggersStrippedSuffix.size) {
                ls.set("sterinames", result);
                this.setState({
                  isLoading: false
                });                
              }
            });
          }
        } else {
          this.setState({
            isLoading: false
          });
        }
      }    
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  syncTime = () => {
    let values = new Set(Object.values(this.getSteriLoggersStrippedSuffix()));
    axios.get(Array.from(values)[0] + "/security"
          ).then(response => {
              if (response.status === 200) {
                let responseData = response.data["Alpha Micro Tech Inc."];  
                
                const time = responseData[4]["Time"];
                const date = responseData[5]["Date"];
                const steriDate = new Date(date + " " + time);
                const steriEpoch = Math.floor(steriDate.getTime()/1000);
                const currentDate = new Date();
                const currentEpoch = Math.floor(currentDate.getTime()/1000);
                
                // if time difference exceeds 10 minutes, sync clocks.
                if (Math.abs(steriEpoch - currentEpoch) > 600 ) {
                  for (let steriLoggerUri of values) {
                    axios.get(steriLoggerUri + "/T" + (currentEpoch - (currentDate.getTimezoneOffset() * 60))
                        ).then(response => {
                            if (response.status === 200) {
                              console.log("time set properly.");
                            }
                        }).catch(error => {
                            console.log(error);
                        });
                    }
                }
              }
          }).catch(error => {
              console.log(error);
          });
  }

  getSteriLoggersStrippedSuffix = () => {
    let steriLoggersStrippedSuffix = {};
    if (this.state.steriLoggers) {
      for (const [key, value] of Object.entries(this.state.steriLoggers)) {
          let suffix = value.indexOf("Steri");
          // Strip off dual unit suffixes.
          if (suffix !== -1) {
              value = value.substring(0, suffix - 1);
          }
          steriLoggersStrippedSuffix[key] = value;
      }
    }
    
    return steriLoggersStrippedSuffix;
  }

  render() {
    const { classes } = this.props;
    let steriLoggersStrippedSuffix = {};
    if (this.state.steriLoggers) {
      for (const [key, value] of Object.entries(this.state.steriLoggers)) {
          let suffix = value.indexOf("Steri");
          // Strip off dual unit suffixes.
          if (suffix !== -1) {
              value = value.substring(0, suffix - 1);
          }
          steriLoggersStrippedSuffix[key] = value;
      }
    }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, this.state.open && classes.appBarShift)}>
          <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.menuButtonHidden,
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              <img alt="Logo" src={logo} style={{verticalAlign: "bottom", paddingRight: "5px"}} /> Alpha Micro Tech Inc.
            </Typography>
            <Button variant="contained" target="_blank" href="https://www.alphamicrotech.ca/shop">Store</Button>
            <IconButton color="inherit" onClick={() => this.setState({manualEntryDialogOpen: true})}>
                <EditIcon />
            </IconButton> 
            <IconButton color="inherit" onClick={this.refreshSteriloggers}>
                <RefreshIcon />
            </IconButton> 
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            
          <ListItem component={Link} to="/" button>
            <ListItemIcon>
              <NotesIcon />
            </ListItemIcon>
              <ListItemText primary="Cycle Listing" />
          </ListItem>
          <ListItem component={Link} to="/barcodes"  button>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
              <ListItemText primary="Barcodes" />
          </ListItem>
          <ListItem component={Link} to="/biologicals"  button>
            <ListItemIcon>
              <PublicIcon />
            </ListItemIcon>
              <ListItemText primary="Biological Indicators" />
          </ListItem>
          <ListItem component={Link} to="/users"  button>
            <ListItemIcon>
                <PeopleIcon />
            </ListItemIcon>
              <ListItemText primary="Users" />
          </ListItem>
          <ListItem component={Link} to="/contents"  button>
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
              <ListItemText primary="Contents" />
          </ListItem>
              <ListItem component={Link} to="/settings"  button>
                  <ListItemIcon>
                      <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
              </ListItem>
          {/* {qs.parse(this.props.location.search).demo !== undefined &&
          <div>
          <ListItem component={Link} to="/users"  button>
            <ListItemIcon>
                <PeopleIcon />
            </ListItemIcon>
              <ListItemText primary="Users" />
          </ListItem>
          <ListItem component={Link} to="/contents"  button>
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
              <ListItemText primary="Contents" />
          </ListItem>
          </div>
          } */}
          </List>
        </Drawer>
        <main className={classes.content}>
        
        <Dialog open={this.state.manualEntryDialogOpen} fullWidth={true} maxWidth="sm">
        <DialogTitle id="form-dialog-title">Enter SteriScan IPs</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="steriIps"
            onChange={this.handleChange}
            id="name"
            label="IPs (192.x.x.1, 192.x.x.2, etc)"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({manualEntryDialogOpen: false})} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleManualEntry} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
        
          <div className={classes.appBarSpacer} />
          <Typography component="div" className={classes.chartContainer}>
            <Route exact path="/" render={(props) => <CycleListing {...props} steriLoggers={this.state.steriLoggers} isLoading={this.state.isLoading} mock={this.state.mock} />} />
            <Route path="/users" render={(props) => <SteriloggerList type="users" steriLoggers={this.state.steriLoggers} isLoading={this.state.isLoading} mock={this.state.mock} />} />
            <Route path="/contents" render={(props) => <SteriloggerList type="contents" steriLoggers={this.state.steriLoggers} isLoading={this.state.isLoading} mock={this.state.mock} />} />
            <Route path="/barcodes" render={(props) => <BarcodeSearch steriLoggers={this.state.steriLoggers} mock={this.state.mock} />}  />
            <Route path="/biologicals" render={(props) => <SteriloggerList type="bi" steriLoggers={this.state.steriLoggers} isLoading={this.state.isLoading} mock={this.state.mock} />} />
            <Route path="/settings" render={() => <Settings steriLoggers={steriLoggersStrippedSuffix} isLoading={this.state.isLoading} mock={this.state.mock} />} />
            <Route path="/forgot" render={(props) => <ForgotPassword {...props} steriLoggers={steriLoggersStrippedSuffix} mock={this.state.mock} />} />
            <Route path="/test" render={(props) => <Test />} />
          </Typography>
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Dashboard));